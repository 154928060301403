import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom'; // Import Link

import logo from '../Img/logo2.png';

function BasicExample() {
  return (

    <div className='navigate'>
    <Navbar expand="lg" >
      <Container>
        <Navbar.Brand as={Link} to="/"  className='logo'>
          <img src={logo} alt="Logo" />
          <h1>The Salon Company</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>

            <NavDropdown title="About" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/About">About Salon </NavDropdown.Item>
             
            </NavDropdown>

            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="">MakeUp</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="">Hair Style</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="">Nails</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="">Artist Makup</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link as={Link} to="/Gallery">Gallery</Nav.Link>

            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>

            <Nav.Link as={Link} to="/Appoint">Appointment</Nav.Link>



          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    </div>
  );
}

export default BasicExample;

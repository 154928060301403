import React, { useState, useEffect } from 'react';
import slide1 from '../Img/slide1.jpg';
import slide2 from '../Img/slide2.jpg';
import slide3 from '../Img/slide3.jpg';

const Header = () => {
  const [currentSlide, setCurrentSlide] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => {
        if (prevSlide === 1) {
          return 2;
        } else if (prevSlide === 2) {
          return 3;
        } else {
          return 1;
        }
      });
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className='header'>
      <img src={currentSlide === 1 ? slide1 : (currentSlide === 2 ? slide2 : slide3)} alt={`Slide ${currentSlide}`} />

      <h1>The Salon Company
      </h1>
    </div>
  );
};

export default Header;

import React, { useState } from 'react';
import Footer from '../Components/Footer';
import WhatsAppButton from 'react-whatsapp-button';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    address: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);

    // Use the react-whatsapp-button library to open WhatsApp with the form data
    sendToWhatsApp(formData);
  };

  const sendToWhatsApp = (data) => {
    // Replace '7379951541' with the actual WhatsApp number
    const phoneNumber = '+919918361836';
    const message = `Name: ${data.name}\nEmail: ${data.email}\nContact: ${data.contact}\nAddress: ${data.address}`;
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <>
      <br /><br />
      <center><h1>Contact Us </h1></center>
      <br /><br />
      <div className='contact'>
        <div className='contact2'>
          <form onSubmit={handleSubmit}>
            {/* Your form inputs */}
            <label htmlFor='name'>Name:</label>
            <input
              type='text'
              id='name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              required
            />

            <label htmlFor='email'>Email:</label>
            <input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label htmlFor='contact'>Contact:</label>
            <input
              type='text'
              id='contact'
              name='contact'
              value={formData.contact}
              onChange={handleChange}
              required
            />

            <label htmlFor='address'>Address:</label>
            <input
              type='text'
              id='address'
              name='address'
              value={formData.address}
              onChange={handleChange}
              required
            />

            <button type='submit'>Submit</button>
          </form>
        </div>

        <div className='contact2'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14235.825523062365!2d80.988945!3d26.8731269!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be33bead47b61%3A0xc74b3e7a94abf327!2sThe%20Salon%20Company!5e0!3m2!1sen!2sin!4v1709635089653!5m2!1sen!2sin" style={{ width: '100%', height: '400px', paddingTop: '15px' }} referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>

      <Footer />

      {/* WhatsApp Button */}
      <WhatsAppButton
        phoneNumber="+919918361836"
        message={`Name: ${formData.name}\nEmail: ${formData.email}\nContact: ${formData.contact}\nAddress: ${formData.address}`}
      />
    </>
  );
};

export default ContactForm;

import React from 'react'
import Header from './Header'
import Section from './Section'
import Services from './Services'
import Media from './media'
import Footer from './Footer'


const Layout = () => {
  return (
    < >

      <div className='mains'>

        <Header />
        <Section />
        <Services />
        <Media />
        <Footer />
      </div>
    </>
  )
}

export default Layout
import React from 'react'

import girl from '../Img/Girl.jpg';
import girl2 from '../Img/banner2.jpg'

const Video = () => {
  return (
    <>

      <div className='media'>
        <div className='media2'>
          <img src={girl} />
        </div>
        <div className='media2'>
          <br />
          <h1 style={{ color: 'white' }}>Our Promise</h1>
          <p>Our comprehensive range of services is designed to bring out the best version of each client, including pre-wedding beauty rituals, Bridals, and Nail Care. Whether you're seeking a fashionable haircut, a captivating hair color transformation, a rejuvenating skincare treatment, or meticulous nail care, our dedicated team stays abreast of the latest techniques and trends in the industry, backed by science.
          </p>

          <p>Our primary focus is to offer a sanctuary of clean beauty, where we reject the use of any harmful chemicals that might compromise the health and radiance of your hair and skin</p>

        </div>
      </div>



      <div className='media'>
        <div className='media2'>
          <img src={girl2} />
        </div>
        <div className='media2'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14235.825523062365!2d80.988945!3d26.8731269!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be33bead47b61%3A0xc74b3e7a94abf327!2sThe%20Salon%20Company!5e0!3m2!1sen!2sin!4v1709635089653!5m2!1sen!2sin" style={{width:'98%',  height:'95%', paddingTop:'15px'}} referrerpolicy="no-referrer-when-downgrade"></iframe>

        </div>
      </div>



    </>
  )
}

export default Video
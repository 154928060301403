import React from 'react'
import banner from '../Img/banner.jpg'
import Footer from '../Components/Footer'
const About = () => {
  return (
    <>

      <div className='section'>

        <div className='section2'>
          <h1>The Salon Company</h1>
          <h2>Beauty parlour in Lucknow</h2>
          <p> " Welcome to the most celebrated salon of India. At The Salon Company we are proud of our heritage of having served discerning Ladies and gentlemen for over Ten years now. To ensure you have a delightful experience we have worked hard to create an environment of luxury and relaxation. Our service providers have been trained to ensure you get treated like Royalty. you could choose from the range of services on offer or speak to one of our consultants to give you bespoke services. Over the years the Fashion and Beauty industry has recognised our efforts. Our biggest reward though has been the recognition we have received from our customers."</p>
        </div>


        <div className='section2'>
          <img src={banner} />
        </div>

      </div>


      <Footer/>

    </>
  )
}

export default About
import React from 'react';
import Footer from '../Components/Footer';

import gal1 from '../Img/Girl.jpg';
import gal2 from '../Img/makeup.jpg';
import gal3 from '../Img/makeup2.jpg';
import gal4 from '../Img/post.jpg';
import gal5 from '../Img/poster.jpg';
import gal6 from '../Img/poster3.jpg';
import gal7 from '../Img/poster4.jpg';
import gal8 from '../Img/newpost.jpg';

const Gallery = () => {
  const galleryImages = [gal1, gal2, gal3, gal4, gal5, gal6, gal7, gal8];

  return (
    <>

      <br />
      <center><h1>Gallery</h1></center>
      <br />

      <div className="image-gallery">
        {galleryImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            className="fixed-size-image"
          />
        ))}
      </div>

      <Footer />
    </>
  );
};

export default Gallery;

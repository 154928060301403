import React, { useState } from 'react';
import Footer from '../Components/Footer';

const Appoint = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    date: '',
    time: '',
    field: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic here for form submission (e.g., sending data to the server)
    console.log('Form submitted:', formData);
    // Reset form fields after submission
    setFormData({
      name: '',
      email: '',
      mobile: '',
      date: '',
      time: '',
      field: '',
    });

    // Open WhatsApp with the pre-filled message
    sendToWhatsApp();
  };

  const sendToWhatsApp = () => {
    const phoneNumber = '+919918361836'; // Replace with your actual WhatsApp number
    const message = `Name: ${formData.name}\nEmail: ${formData.email}\nMobile: ${formData.mobile}\nDate: ${formData.date}\nTime: ${formData.time}\nTitle: ${formData.field}`;
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <>
      <center><h1>Book Appointment</h1></center>

      <div className='appointment'>
        <form onSubmit={handleSubmit}>
          {/* Your form inputs */}
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label htmlFor="mobile">Mobile No:</label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
          />

          <label htmlFor="date">Date:</label>
          <input
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />

          <label htmlFor="time">Time:</label>
          <input
            type="time"
            id="time"
            name="time"
            value={formData.time}
            onChange={handleChange}
            required
          />

          <label htmlFor="field">Title:</label>
          <input
            type="text"
            id="field"
            name="field"
            value={formData.field}
            onChange={handleChange}
            required
          />

          <button type="submit">Submit</button>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default Appoint;

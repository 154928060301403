import React from 'react'

import banner1 from '../Img/poster.jpg'
import banner2 from '../Img/poster2.jpg'
import banner3 from '../Img/poster3.jpg'
import banner4 from '../Img/makeup.jpg'
import banner5 from '../Img/poster4.jpg'
import banner6 from '../Img/newpost.jpg'

const Services = () => {
    return (
        <>

            <br /><br />
            <center className='serve'><h1>OUR SERVICES</h1></center>
            <div className='services'>
                <div className='services2'>
                    <img src={banner1} />
                    <h1>MakeUp</h1>
                </div>
                <div className='services2'>
                    <img src={banner2} />
                    <h1>Hair Style</h1>
                </div>
                <div className='services2'>
                    <img src={banner3} />
                    <h1>Nails </h1>
                </div>
            </div>

            <div className='services'>
                <div className='services2'>
                    <img src={banner4} />
                    <h1>Facial  Beauty</h1>
                </div>
                <div className='services2'>
                    <img src={banner5} />
                    <h1>Coloring Roots</h1>
                </div>
                <div className='services2'>
                    <img src={banner6} />
                    <h1>Blow Dry</h1>
                </div>
            </div>

        </>
    )
}

export default Services
import React from 'react'
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <div className='footer'>

            <div className='footer2'>
                <h1>The Salon Company</h1>
                <p> Welcome to the most celebrated salon of India. At The Salon Company we are proud of our heritage of having served discerning Ladies and gentlemen for over Ten years now. </p>
            </div>

            <div className='footer2'>
                <h1>Quick Link</h1><br /><br />
                <center>

                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link><br />
                    <Link to="/About" style={{ textDecoration: 'none', color: 'black' }}>About</Link><br />
                    <Link to="/Gallery" style={{ textDecoration: 'none', color: 'black' }}>Gallery</Link><br />
                    <Link to="/Contact" style={{ textDecoration: 'none', color: 'black' }}>Contact</Link><br />
                    <Link to="/Appoint" style={{ textDecoration: 'none', color: 'black' }}>Appointment</Link>
                </center>
            </div>

            <div className='footer2'>
                <h1>Services</h1><br /><br />
                <center>

                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>MakeUp </Link><br />
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Hairstyle</Link><br />
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>PartymakeUp</Link><br />
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>MicroBlading</Link><br />
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>ArtistmakeUp</Link>
                </center>
            </div>

            <div className='footer2'>
                <h1>Reach Us </h1>
                <p>IndusInd Bank, 628/010A-CC, FAIZABAD ROAD, INDRA NAGAR, ADJOIN, Indira Nagar, Lucknow, Uttar Pradesh 226016</p>
                <p>+91 99183 61836</p>
                {/* <p>thesaloncompany@gmail.com</p> */}
                <p>Timing: 10 AM TO 08 PM</p>


            </div>


        </div>
    )
}

export default Footer
import React from 'react'
import social1 from '../Img/insta.jpg'
import social2 from '../Img/cal.jpg'
import social3 from '../Img/whats.jpg'

const Head = () => {
    return (
        <div className='head'>

            <div className='head2'>
                <p>Timing:  10 AM TO 08 PM  </p>
            </div>


            <div className='head2' style={{ display: 'flex' }}>
                <p> +91 99183 61836</p>
                <a href='https://www.instagram.com/thesaloncompany.co.in/?hl=en' style={{ marginLeft: '10px' }}><img src={social1} /></a>
                <a href='Tel:99183 61836' style={{ marginLeft: '10px' }} ><img src={social2} /></a>
                <a href="https://api.whatsapp.com/send?phone=9918361836" style={{ marginLeft: '10px' }}>
                    <img src={social3} alt="WhatsApp" />
                </a>
            </div>


            <div className='head2'>
                <p>IndusInd Bank, 628/010A-CC, Faizabad Road, Indira Nagar </p>
            </div>


        </div>
    )
}

export default Head
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Components/Layout';
import Navbar from './Components/Navbar';
import About from './Pages/About';
import Contact from './Pages/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from './Components/Head';


import Gallery from './Pages/Gallery';
import Appoint from './Pages/Appoint';

function App() {
  return (
    <div className='mains'>


      <BrowserRouter basename=''>
        <Head />
        <Navbar />
        <Routes>
          <Route path="*" element={<Layout />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Appoint" element={<Appoint />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
